import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../screens/Login';
import Home from '../screens/Home';
import ResetPassword from '../screens/resetPassword';
import ForgetPassword from '../screens/forgetPassword';

const Navigation = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/ForgetPassword" element={<ForgetPassword />} />
                <Route path="/ResetPassword" element={<ResetPassword />} />
                {/* Use Navigate for redirection */}
                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    );
};

export default Navigation;