import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import * as IMAGE_CONSTANT from '../common/constants/image_constants';
import { QueryRequest, MutateRequest } from '../helpers/api/service_helper';
import { setItemOnLocalStorage, clearValuesOnLogout } from '../helpers/api/utils';
import { notifyError, notifySuccess } from '../common/notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, StepLabel, Step, Stepper, CircularProgress } from '@mui/material';
import OtpInput from 'otp-input-react';
import { SENT_EMAIL_OTP_RESET_PASSWORD, FORGOT_PASSWORD } from '../helpers/api/Mutation';
import { VERIFY_EMAIL_OTP } from '../helpers/api/Queries';

const steps = [
    'Enter the Email ID',
    'Verify OTP',
    'Enter New Password',
];

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [userID, setUserID] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

    const handleUsernameChange = (e) => {
        setUserID(e.target.value);
        setError('');
    };

    const handleOtpChange = (value) => {
        setOtp(value);
        setError('');
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setError('');
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        clearValuesOnLogout();

        if (activeStep === 0) {
            try {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                setLoading(true);
                if (!userID.trim()) {
                    return setError('Email ID is required.');
                }
                if (!emailRegex.test(userID.trim())) {
                    return setError('Invalid email ID.');
                }
                const response = await MutateRequest(SENT_EMAIL_OTP_RESET_PASSWORD, { email: userID , type:"resetPassword" });

                if (response.data.sentUserOtp.status === "Failure") {
                    notifyError(response.data.sentUserOtp.error.message);
                    setError(response.data.sentUserOtp.error.message);
                } else {
                    setActiveStep(1);
                    notifySuccess("OTP has been successfully sent to your email.");
                    setError('')
                }
            } catch (error) {
                console.error('Error occurred while sending OTP', error);
                notifyError('Error occurred while sending OTP');
            } finally {
                setLoading(false);
            }
        } else if (activeStep === 1) {
            try {
                setLoading(true);
                if (otp.length < 4) {
                    setError('Please enter the 4-digit OTP.');
                    return;
                }
                const response = await QueryRequest(VERIFY_EMAIL_OTP, { email: userID, emailOtp: otp });
                if (response.data.verifyEmailOTP.status === "Failure") {
                    return setError("Invalid OTP")
                } else {
                    setActiveStep(2);
                    notifySuccess("OTP has been successfully verified");
                    setOtpVerified(true)
                    setError('')
                }
            } catch (error) {
                console.error('Error occurred while verifying OTP', error);
                notifyError('Error occurred while verifying OTP');
            } finally {
                setLoading(false);
            }
        } else if (activeStep === 2) {
            try {
                setLoading(true);
                const passwordRegex = /^(?=.*[!@#$%^&*()_+[\]{}|<>?])[\w!@#$%^&*()_+[\]{}|<>?]{8,}$/;
                if (!newPassword.trim()) {
                    setError('Please enter the password');
                    return;
                };
                if (!confirmPassword.trim()) {
                    setError('Please enter the confirm password');
                    return;
                }
                if (!passwordRegex.test(newPassword)) {
                    setError('Password must be at least 8 characters long and include at least one special character');
                    return false;
                }
                if (newPassword !== confirmPassword) {
                    setError('Passwords do not match.');
                    return;
                }
                const response = await MutateRequest(FORGOT_PASSWORD, { mobileNumber: userID, newPassword: newPassword });

                if (response.data.forgotPassword.status === "Failure") {
                    notifyError("Error occurred resetting password");
                } else {
                    notifySuccess('Your password has been successfully changed');
                    setTimeout(function () {
                        navigate("/login")
                    }, 2000);
                    setError('')
                }
            } catch (error) {
                console.error('Error occurred while setting Password', error);
                notifyError('Error occurred while setting Password');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div
            className="h-screen w-screen bg-cover"
            style={{
                backgroundImage: `url(${IMAGE_CONSTANT.LOGIN_BACKGROUND_IMAGE})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div>
                <ToastContainer />
            </div>
            <div className="flex items-center justify-center h-screen">
                <div className="bg-white p-8 shadow-2xl rounded-md md:w-1/2 sm:w-1/3 lg:w-1/3 ">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img className="mx-auto h-14 w-auto" src={IMAGE_CONSTANT.UKIDDIY_LOGO} alt="ukiddiy" />
                        <div>
                            <h2 className="text-center text-3xl font-bold leading-9 tracking-tight text-blue-900">ukiddiy</h2>
                            <h2 className="text-center pl-5 text-2xl font-bold leading-9 tracking-tight " style={{ color: "#5B78BE" }}>school</h2>
                        </div>
                    </div>
                    <h2 className="text-center pl-5 text-xl font-bold leading-9 tracking-tight mb-2" style={{ color: "black" }}>Forgot Password</h2>

                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <form onSubmit={handleSubmit}>
                        {activeStep === 0 && (
                            <div className={`relative mt-3 ${error ? 'border-red-500' : ''}`}>
                                <input
                                    value={userID}
                                    onChange={handleUsernameChange}
                                    placeholder="You will receive an OTP on this email address"
                                    type="text"
                                    id="username"
                                    name="username"
                                    disabled={activeStep !== 0}
                                    className={`w-full border p-3 rounded-md pl-10 text-sm ${error ? 'border-red-500' : 'border-gray-300'}`}
                                />
                                <FontAwesomeIcon icon={faUserCircle} className="text-xl absolute left-3 top-3.5  text-gray-400" />
                            </div>
                        )}
                        {activeStep === 1 && (
                            <div className="flex justify-center mt-3">
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    secure
                                    separator={<span className="mx-2">-</span>}
                                    inputClassName="border border-black p-2 text-center text-black text-lg"
                                    disabled={otpVerified}
                                />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <>
                                <div className={`relative mt-3 ${error ? 'border-red-500' : ''}`}>
                                    <input
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        placeholder="New Password"
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        className={`w-full border p-3 rounded-md pl-10 text-sm ${error ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    <FontAwesomeIcon icon={faLock} className="text-xl absolute left-3 top-3.5 text-gray-400" />
                                </div>
                                <div className={`relative mt-3 ${error ? 'border-red-500' : ''}`}>
                                    <input
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        placeholder="Confirm New Password"
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className={`w-full border p-3 rounded-md pl-10 text-sm ${error ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    <FontAwesomeIcon icon={faLock} className="text-xl absolute left-3 top-3.5 text-gray-400" />
                                </div>
                            </>
                        )}

                        <div className='h-10 flex justify-center'>
                            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                        </div>

                        <div className="flex justify-between">
                            <button
                                type="button"
                                className="w-1/2 mt-3 mr-2 bg-white text-blue-900 border border-blue-900 p-3 rounded-md"
                                onClick={() => {
                                    if (activeStep > 0) {
                                        setActiveStep(activeStep - 1);
                                    } else {
                                        navigate("/login");
                                    }
                                }}
                            >
                                <p className="text-xs">BACK</p>
                            </button>
                            <button
                                type="submit"
                                className="w-1/2 mt-3 ml-2 bg-blue-900 text-white p-3 rounded-md hover:bg-blue-900"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress color="inherit" size={16} />
                                ) : (
                                    <p className="text-xs">{activeStep === steps.length - 1 ? 'RESET' : 'NEXT'}</p>
                                )}
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
