import React, { useState, useEffect } from 'react';
// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
import {
    Menu as MenuIcon,
    AccountCircle as AccountCircleIcon,
    ExitToApp as ExitToAppIcon,
    Mail as MailIcon,
    Notifications as NotificationsIcon,
    MoreVert as MoreIcon,
    AccountCircle
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import * as IMAGE_CONSTANT from "../../common/constants/image_constants"
import { clearValuesOnLogout } from '../../helpers/api/utils';
import { getItemFromLocalStorage } from '../../helpers/api/utils';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
    AppBar,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    IconButton,
    Toolbar,
    Box,
    CircularProgress
} from '@mui/material';
import { notifyError, notifySuccess } from '../../common/notifications';
import { MutateRequest } from '../../helpers/api/service_helper';
import { RESET_PASSWORD } from '../../helpers/api/Mutation';


export default function Navbar({ tabBar, setTabBar, isExpanded, setExpanded, }) {
    function handleleftbar() {
        setTabBar(!tabBar)
        setExpanded(true);

    }
    const [name, setName] = useState("uKiddiy Admin");
    const [userMobileNumber, setUserMobileNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const teacherIdGet = getItemFromLocalStorage('teacher_id');
        const schoolIdGet = getItemFromLocalStorage('school_id');
        const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
        const user_mobileNumber = getItemFromLocalStorage('user_mobileNumber');
        setUserMobileNumber(user_mobileNumber)
        if (superAdminIdGet) {
            setName("Super Admin");
        } else if (schoolIdGet) {
            setName(schoolIdGet.name);
        } else if (teacherIdGet) {
            setName(teacherIdGet.name);
        }
    }, [])


    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [resetError, setResetError] = useState('');

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleResetPasswordOpen = () => {
        setOpen(true);
    };

    const handleResetPasswordClose = () => {
        setConfirmNewPassword("")
        setCurrentPassword("")
        setNewPassword("")
        setResetError("")
        setOpen(false);
    };

    const handleResetPasswordSubmit = async () => {
        const passwordRegex = /^(?=.*[!@#$%^&*()_+[\]{}|<>?])[\w!@#$%^&*()_+[\]{}|<>?]{8,}$/;

        if (!currentPassword) {
            return setResetError("Enter Your Current Password")
        };
        if (!newPassword) {
            return setResetError("Enter Your New Password")
        };
        if (!confirmNewPassword) {
            return setResetError("Enter Your Confirm New Password")
        };
        if (newPassword !== confirmNewPassword) {
            return setResetError("Enter Your Confirm New Password")
        };
        if (!passwordRegex.test(newPassword)) {
            return setResetError('Password must be at least 8 characters long and include at least one special character');
        };
        if (newPassword !== confirmNewPassword) {
            return setResetError('Passwords do not match');
        };
        if (currentPassword === confirmNewPassword) {
            return setResetError('Current password and new password should not be same');
        }
        try {
            setLoading(true);

            const data = {
                email: userMobileNumber,
                password: currentPassword,
                newPassword: newPassword,
                email_otp_required: "No"
            }
            const response = await MutateRequest(RESET_PASSWORD, data);

            if (response.data.resetPassword.status === "Success") {
                notifySuccess('Your password has been successfully changed');
                handleResetPasswordClose();
            } else {
                setResetError(response.data.resetPassword.error.message);
            }

        } catch (error) {
            console.error("An error occurred:", error);
            notifyError("An error occurred. Please try again.");
            handleResetPasswordClose();
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        clearValuesOnLogout()
        navigate('/login')
    }
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >

            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <MenuItem onClick={handleResetPasswordOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <LockResetIcon />
                </IconButton>
                <p>Reset password</p>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            {/* <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={1} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
             */}
            <MenuItem onClick={handleResetPasswordOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <LockResetIcon />
                </IconButton>
                <p>Reset password</p>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <ExitToAppIcon />
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    );

    const buttonStyle = {
        border: '2px solid #1E40AF',
        color: '#FFFFFF',
        backgroundColor: '#1E40AF',
        borderRadius: '5px',
        transition: 'all 0.3s',
        cursor: 'pointer',
    };


    return (
        <Box sx={{ flexGrow: 1, position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
            <AppBar position="fixed" style={appBarStyle}>
                <Toolbar>
                    <img
                        className="h-12 "
                        src={IMAGE_CONSTANT.UKIDDIY_SCHOOL_LOGO}
                        alt="ukiddiy"
                    />
                    <IconButton
                        size="large"
                        style={iconColor}
                        aria-label="open drawer"
                        sx={{ ml: 3 }}
                        onClick={handleleftbar}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography onClick={handleleftbar} className='cursor-pointer' style={{ color: "#0E04A1" }} sx={{ fontSize: "20px" }} component="div" fontWeight="bold">
                        {`Welcome  ${name}`}
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            style={iconColor}
                        >
                            <Badge badgeContent={1} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleLogout}
                            style={iconColor} >
                            <ExitToAppIcon />
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            style={iconColor}
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            style={iconColor}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

            <Dialog open={open} onClose={handleResetPasswordClose}>
                <DialogTitle>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        Reset Password
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="currentPassword"
                        label="Current Password"
                        type="password"
                        fullWidth
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '10px',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                            },
                            '& .Mui-focused': {
                                color: '#333',
                            },
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="newPassword"
                        label="New Password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '10px',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                            },
                            '& .Mui-focused': {
                                color: '#333',
                            },
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="confirmNewPassword"
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '10px',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                            },
                            '& .Mui-focused': {
                                color: '#333',
                            },
                        }}
                    />
                </DialogContent>
                <Typography sx={{ alignSelf: "center", fontSize: '1rem', color: "red" }}>
                    {resetError}
                </Typography>
                <DialogActions style={{ justifyContent: "space-between", padding: 20 }}>
                    <Button onClick={handleResetPasswordClose} style={buttonStyle}
                        color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleResetPasswordSubmit} style={buttonStyle}
                        color="primary">

                        {loading ? <CircularProgress color="inherit" size={30} /> : 'Submit'}

                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const iconColor = {
    color: "#424242",
}

const appBarStyle = {
    backgroundColor: '#D6E2FF',
};




