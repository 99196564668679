import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import * as IMAGE_CONSTANT from '../common/constants/image_constants';
import { QueryRequest, MutateRequest } from '../helpers/api/service_helper';
import { LOGIN_USER_QUERY } from '../helpers/api/Queries';
import { setItemOnLocalStorage, clearValuesOnLogout } from '../helpers/api/utils';
import { notifyError } from '../common/notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { SENT_EMAIL_OTP_RESET_PASSWORD } from '../helpers/api/Mutation';
import CircularProgress from '@mui/material/CircularProgress';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const ResetPasswordModal = ({ setOpen, open, data }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const buttonStyle = {
        border: '2px solid #1E40AF',
        color: '#FFFFFF',
        backgroundColor: '#1E40AF',
        borderRadius: '5px',
        transition: 'all 0.3s',
        cursor: 'pointer',
    };

    async function handleContinue() {
        try {
            setLoading(true);
            const response = await MutateRequest(SENT_EMAIL_OTP_RESET_PASSWORD, { email: data[0] , type:"resetPassword" });

            if (response.data.sentUserOtp.status === "Failure") {
                notifyError("Error occurred while sending mail OTP");
            } else {
                navigate("/ResetPassword", { state: { resetPasswordDetails: data } });
            }
        } catch (error) {
            console.error("An error occurred:", error);
            notifyError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle fontWeight={600}>
                For security reasons, we require all new users to reset their passwords on their first login.
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    This helps ensure the safety of your account and personal information.
                </Typography>
                <Typography variant="body1">
                    Please follow these steps to reset your password
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Click "Continue" to receive an OTP in your registered email.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Please check your registered email address, {data[0]}, for an OTP sent from ukiddiy@gmail.com.
                        </ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={() => setOpen(false)}
                    style={buttonStyle}
                >
                    BACK
                </Button>

                <Button
                    onClick={handleContinue}
                    style={buttonStyle}
                    disabled={loading}
                >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Continue'}
                </Button>
            </DialogActions>
        </Dialog>

    );
};


const Login = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPassModal, setResetPassModal] = useState(false);
    const [resetPassData, setResetPassData] = useState([]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setError('');
    };
    // const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         AuthenticateLogin(e);
    //     }
    // };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError('');
    };

    const isUsernameValid = () => {
        return !!username.trim();
    };

    const isPasswordValid = () => {
        return !!password.trim();
    };
    useEffect(() => {
        // Disable the back button
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    const handlePopstate = () => {
        // Disable the back button by pushing the state again
        window.history.pushState(null, null, window.location.pathname);
    };

    const AuthenticateLogin = async () => {
        try {
            setLoading(true);
            // Check if both username and password are valid for extra security purpose 
            if (!isUsernameValid() || !isPasswordValid()) {
                setError('Username and password are required.');
                return;
            }
            const response = await QueryRequest(LOGIN_USER_QUERY, { mobileNumber: username, password, });
            const { userId, token, name, school, teacher, mobileNumber, emailVerifiedDate } = response.data.loginUser;


            if (response.data.loginUser.error && response.data.loginUser.error.statusCode === "422") {
                setError(response.data.loginUser.error.message);
                notifyError(response.data.loginUser.error.message);
                return;
            }
            if (response.data.loginUser.error && response.data.loginUser.error.statusCode === "401") {
                setResetPassModal(true);
                setResetPassData([username, password, response.data.loginUser]);
                return;
            }

            if (!response.data.loginUser.error) {
                setItemOnLocalStorage('user_id', userId);
                setItemOnLocalStorage('user_token', token);
                setItemOnLocalStorage('user_name', name);
                setItemOnLocalStorage('user_mobileNumber', mobileNumber);
                if (mobileNumber === "ukiddiy@gmail.com" || mobileNumber === "superadmin") {
                    setItemOnLocalStorage('superAdmin_id', mobileNumber);
                    return navigate('/home');
                } else if (school && school.id) {
                    setItemOnLocalStorage('school_id', school);
                    return navigate('/home');
                } else if (teacher && teacher.id && teacher.school.id) {
                    setItemOnLocalStorage('teacher_school_id', teacher.school.id);
                    setItemOnLocalStorage('teacher_id', teacher);
                    return navigate('/home');
                } else {
                    notifyError("User Not Found");
                    setError("User Not Found");
                }
            } else {
                notifyError(response.data.loginUser.error.message);
                setError("Invalid OTP / Password");
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            notifyError('Authentication failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        clearValuesOnLogout();
        if (!isUsernameValid() || !isPasswordValid()) {
            notifyError('Username and password are required.');
            setError("Username and password are required.");
            return;
        }
        await AuthenticateLogin();
    };
    return (

        <div
            className="h-screen w-screen bg-cover"
            style={{
                backgroundImage: `url(${IMAGE_CONSTANT.LOGIN_BACKGROUND_IMAGE})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div>
                <ToastContainer />
                <ResetPasswordModal setOpen={setResetPassModal} open={resetPassModal} data={resetPassData} />
            </div>
            <div className="flex items-center justify-center h-screen">
                <div className="bg-white p-8 shadow-2xl rounded-md md:w-1/2 sm:w-1/3 lg:w-1/3 ">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img className="mx-auto h-20 w-auto" src={IMAGE_CONSTANT.UKIDDIY_LOGO} alt="ukiddiy" />
                        <div>
                            <h2 className="text-center text-3xl font-bold leading-9 tracking-tight text-blue-900">ukiddiy</h2>
                            <h2 className="text-center pl-5 text-2xl font-bold leading-9 tracking-tight " style={{ color: "#5B78BE" }}>school</h2>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className={`relative mt-3 ${error ? 'border-red-500' : ''}`}>
                            <input
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder="Email ID"
                                type="text"
                                id="username"
                                name="username"
                                className={`w-full border p-3 rounded-md pl-10 text-sm ${error ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            <FontAwesomeIcon icon={faUserCircle} className="text-xl absolute left-3 top-3.5 text-gray-400" />
                        </div>

                        <div className={`relative mt-3 ${error ? 'border-red-500' : ''}`}>
                            <input
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Password"
                                type="password"
                                id="password"
                                name="password"
                                className={`w-full border p-3 rounded-md pl-10 text-sm ${error ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            <FontAwesomeIcon icon={faLock} className="absolute left-3 top-4 text-gray-400" />
                        </div>

                        {/* {error && <p className="text-red-500 text-sm mt-2">{error}</p>} */}

                        <button
                            type="submit"
                            className="w-full mt-3 bg-blue-900 text-white p-3 rounded-md hover:bg-blue-900"
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                </div>
                            ) : (
                                <p className="text-xs">LOGIN</p>
                            )}
                        </button>
                    </form>

                    <div className="flex flex-col mt-4 ">
                        <div className=" ">
                            <Link to="/ForgetPassword" className="text-blue-500 underline">Forgot Password?</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
