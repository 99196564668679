import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeAnimated from 'react-select/animated';
import { Calendar } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../../redux/formReducer.js';
import Select from 'react-select';
import { notifyError } from "../../../../common/notifications.js"
import 'react-toastify/dist/ReactToastify.css';
import { setClasses, setIsLoading } from '../../../../redux/common_reducer.js';
import { getItemFromLocalStorage } from '../../../../helpers/api/utils.js';
import { QueryRequest } from '../../../../helpers/api/service_helper.js';
import { GET_CLASS_DROPDOWN_QUERY, GET_PROGRESS_REPORT } from '../../../../helpers/api/Queries.js';
import moment from 'moment';
import ProgressBarChart from './ProgressBarChart.js';

const ChartReportForm = () => {

    const dispatch = useDispatch();
    const filterProgress = useSelector((state) => state.form.progressReportChart);
    const fromSelectedDate = useSelector((state) => state.form.progressReportChart.fromdate);
    const toSelectedDate = useSelector((state) => state.form.progressReportChart.todate);
    const isLoading = useSelector((state) => state.common.isLoading);
    const schools = useSelector((state) => state.common.schools)
    const [selectedSchool, setSelectedSchool] = useState(null);
    const classes = useSelector((state) => state.common.classes);
    const [tableData, setTableData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false);

    const fetchClasses = useCallback(async (selectedSchoolValue) => {
        try {
            // before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
            // this condition is retrieve students based on the loged in user role
            const teacherIdGet = getItemFromLocalStorage('teacher_id');
            const queryParam = {};
            if (teacherIdGet) {
                queryParam.teacherId = teacherIdGet.id;
            } else {
                queryParam.schoolId = selectedSchoolValue;
            }
            const responseClass = await QueryRequest(GET_CLASS_DROPDOWN_QUERY, queryParam);
            const fetchedClass = responseClass.data.getClazzDropDownOptions.options;

            dispatch(setClasses(
                fetchedClass.map((clazz) => ({
                    value: clazz.value,
                    label: clazz.label,
                }))
            ));
        } catch (error) {
            notifyError(`${error}`);
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedSchool) {
            fetchClasses(selectedSchool.value);
        }
    }, [selectedSchool, fetchClasses]);


    const handleClear = () => {
        dispatch(clearForm({ formName: 'progressReportChart' }));
        setSelectedSchool(null);
        setErrors({});
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate school field
        if (!filterProgress.selectSchool) {
            newErrors.selectSchool = 'School is required';
        }

        // Validate from date
        if (!filterProgress.fromdate) {
            newErrors.fromdate = 'From date is required';
        }

        // Validate from date
        if (!filterProgress.selectStudent) {
            newErrors.selectStudent = 'Student is required';
        }

        // Validate to date
        if (!filterProgress.todate) {
            newErrors.todate = 'To date is required';
        }

        // Validate report type
        if (!filterProgress.selectType) {
            newErrors.selectType = 'Select Type is required';
        }

        if (!filterProgress.class) {
            newErrors.class = 'Class is required ';
        }

        // Combine error messages
        let errorMessage = '';
        Object.values(newErrors).forEach((error) => {
            errorMessage += `${error}, `;
        });

        // Remove trailing comma and space
        errorMessage = errorMessage.slice(0, -2);

        // Show single notifyError if there are any errors
        if (errorMessage !== '') {
            notifyError(`${errorMessage}`);
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleView = async () => {
        if (validateForm()) {
            const data = {
                schoolId: filterProgress.selectSchool.value,
                classId: filterProgress.class ? filterProgress.class.value : null,
                fromDate: filterProgress.fromdate,
                toDate: filterProgress.todate,
            };
            try {
                dispatch(setIsLoading(true));
                const response = await QueryRequest(GET_PROGRESS_REPORT, data);
                setTableData(response.data.getKidProgressReport.items);

                if (filterProgress.reportType.value !== 'Class') {
                    setShowTable(true);
                } else {
                    setShowTable(false);
                }
            } catch (error) {
                console.error(error)
                notifyError(`${error}`);
            } finally {
                dispatch(setIsLoading(false));
            }

        }
    };

    const handlechange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectSchool') {
            dispatch(updateForm({ formName: 'progressReportChart', formData: { class: null, selectSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'progressReportChart', formData: { [fieldName]: selectedOption } }));
        }
    };

    const handleDate = (fieldName, date) => {
        // Get the DOB in this format DD/MM/YYYY
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;

        // Update the form data directly within the progressReport slice
        dispatch(updateForm({
            formName: 'progressReportChart',
            formData: {
                [fieldName]: formattedDate,
            },
        }));
    };


    const selectType = [
        { value: 'Task', label: 'Task' },
        { value: 'Self Assessment', label: 'Self Assessment' },
        { value: 'Quiz', label: 'Quiz' },
    ];


    return (
        <div>
            <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form className='mb-20' >
                    <h2 className=' text-xl text-black font-bold mb-3'>Individual Progress Details:</h2>

                    <div className="mb-4 md:flex md:items-center">
                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectSchool ? 'has-error' : ''}`}>
                            <label htmlFor="selectSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {errors.selectSchool ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectSchool ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schools}
                                    isSearchable
                                    isClearable
                                    id="selectSchool"
                                    components={makeAnimated()}
                                    value={selectedSchool}
                                    className='z-50'
                                    onChange={(selectedOption) => handlechange('selectSchool', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.class ? 'has-error' : ''}`}>
                            <label htmlFor="class" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Class
                                {errors.class && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.class ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={classes}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id="class"
                                    name="class"
                                    value={filterProgress.class}
                                    onChange={(selectedOption) => handlechange('class', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectType ? 'has-error' : ''}`}>
                            <label htmlFor="selectType" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Type
                                {errors.selectType ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectType ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={selectType}
                                    isClearable
                                    components={makeAnimated()}
                                    id="selectType"
                                    name="selectType"
                                    value={filterProgress.selectType}
                                    onChange={(selectedOption) => handlechange('selectType', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectStudent ? 'has-error' : ''}`}>
                            <label htmlFor="selectStudent" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Student
                                {errors.selectStudent && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectStudent ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={classes}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id="selectStudent"
                                    name="selectStudent"
                                    value={filterProgress.selectStudent}
                                    onChange={(selectedOption) => handlechange('selectStudent', selectedOption)}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mb-4 md:flex md:items-center">

                        <div className=" me-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                From Date
                                {errors.fromdate ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <DatePicker
                                selected={fromSelectedDate ? moment(fromSelectedDate, 'YYYY-MM-DD').toDate() : null}
                                onChange={(date) => handleDate('fromdate', date)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="  Select from date"
                                icon={<Calendar size={20} />}
                                showIcon
                                className={`border rounded z-50  ${errors.fromdate ? 'border-red-500' : 'border'}`}
                                maxDate={new Date()}
                                minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())}
                            />
                        </div>

                        <div className=" me-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                To Date
                                {errors.todate ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <DatePicker
                                selected={toSelectedDate ? moment(toSelectedDate, 'YYYY-MM-DD').toDate() : null}
                                onChange={(date) => handleDate('todate', date)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="  Select to date"
                                icon={<Calendar size={20} />}
                                showIcon
                                className={`border rounded z-50  ${errors.todate ? 'border-red-500' : 'border'}`}
                                maxDate={new Date()}
                                minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())}
                            />
                        </div>

                    </div>
                    {/* {errors && <div className="text-red-500">{errors}</div>} */}
                </form >


                {/* END OF FORM */}
                <div className="flex mb-28 flex-col md:flex-row justify-end mt-4 space-y-2 md:space-y-0 md:space-x-2">

                    <button
                        type="button"
                        onClick={handleClear}
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                        <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                    </button>
                    <button
                        type="submit"
                        onClick={handleView}
                        disabled={isLoading} // Disable button when loading
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">
                        <FontAwesomeIcon icon={faEye} />
                        {isLoading ? 'Viewing...' : 'View'}
                    </button>

                </div>
                <hr className="border-t border-gray-300 my-8" />
                <div>
                    <>
                        <ProgressBarChart tableData={tableData} />
                    </>
                </div>
            </div>
        </div >
    );
};

export default ChartReportForm