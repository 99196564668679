import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import * as IMAGE_CONSTANT from '../common/constants/image_constants';
import { MutateRequest, QueryRequest } from '../helpers/api/service_helper';
import { setItemOnLocalStorage, clearValuesOnLogout } from '../helpers/api/utils';
import { notifyError, notifySuccess } from '../common/notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { RESET_PASSWORD } from '../helpers/api/Mutation';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('')
    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { resetPasswordDetails } = location.state;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        clearValuesOnLogout();
        const passwordRegex = /^(?=.*[!@#$%^&*()_+[\]{}|<>?])[\w!@#$%^&*()_+[\]{}|<>?]{8,}$/;
        if (!newPassword.trim()) {
            setError('Please enter new password ');
            return false;
        }
        if (!confirmPassword.trim()) {
            setError('Please enter and confirm password');
            return false;
        }

        if (!otp.trim()) {
            setError('Please enter the otp');
            return false;
        }
        if (!passwordRegex.test(newPassword)) {
            setError('Password must be at least 8 characters long and include at least one special character');
            return false;
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        if (resetPasswordDetails[1] === confirmPassword) {
            setError('Current password and new password should not be same');
            return false;
        }
        try {
            setLoading(true);

            const data = {
                email: resetPasswordDetails[0],
                password: resetPasswordDetails[1],
                newPassword: newPassword,
                emailOtp: otp,
                email_otp_required: "Yes"
            }
            const response = await MutateRequest(RESET_PASSWORD, data);

            if (response.data.resetPassword.status === "Success") {
                notifySuccess('Your password has been successfully changed');
                setTimeout(function () {
                    navigate('/login');
                }, 2000);
            } else {
                notifyError(response.data.resetPassword.error.message);
            }

        } catch (error) {
            console.error("An error occurred:", error);
            notifyError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="h-screen w-screen bg-cover" style={{
            backgroundImage: `url(${IMAGE_CONSTANT.LOGIN_BACKGROUND_IMAGE})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div>
                <ToastContainer />
            </div>
            <div className="flex items-center justify-center h-screen">
                <div className="bg-white p-8 shadow-2xl rounded-md md:w-1/2 sm:w-1/3 lg:w-1/3">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img className="mx-auto h-20 w-auto" src={IMAGE_CONSTANT.UKIDDIY_LOGO} alt="ukiddiy" />
                        <div>
                            <h2 className="text-center text-3xl font-bold leading-9 tracking-tight text-blue-900">ukiddiy</h2>
                            <h2 className="text-center pl-5 text-2xl font-bold leading-9 tracking-tight " style={{ color: "#5B78BE" }}>school</h2>
                        </div>
                    </div>
                    <h2 className="text-center pl-5 text-xl font-bold leading-9 tracking-tight mb-5" style={{ color: "black" }}>Reset Password</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={`relative mt-3`}>
                            <input
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New password"
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                className={`w-full border p-3 rounded-md pl-10 text-md`}
                            />
                            <FontAwesomeIcon icon={faLock} className="absolute left-3 top-4 text-gray-400" />
                        </div>
                        <div className={`relative mt-3`}>
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm new password"
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                className={`w-full border p-3 rounded-md pl-10 text-md`}
                            />
                            <FontAwesomeIcon icon={faLock} className="absolute left-3 top-4 text-gray-400" />
                        </div>

                        <div className={`relative mt-3`}>
                            <input
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                maxLength={4}
                                placeholder="Enter email OTP"
                                type="password"
                                id="otp"
                                name="otp"
                                className={`w-full border p-3 rounded-md pl-10 text-md`}
                            />
                            <FontAwesomeIcon icon={faEnvelope} className="absolute left-3 top-4 text-gray-400" />
                        </div>

                        <div className={` mt-3 items-center flex justify-center h-10`}>
                            {error && <p className="text-red-500 text-md mt-2">{error}</p>}
                        </div>
                        <div className="flex justify-between">
                            <button
                                type="button"
                                className="w-1/2 mt-3 mr-2 bg-white text-blue-900 border border-blue-900 p-3 rounded-md"
                                onClick={() => navigate("/login")}
                            >
                                <p className="text-xs">BACK</p>
                            </button>
                            <button
                                type="submit"
                                className="w-1/2 mt-3 ml-2 bg-blue-900 text-white p-3 rounded-md hover:bg-blue-900"
                                disabled={loading}
                            >
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                    </div>
                                ) : (
                                    <p className="text-xs">SUBMIT</p>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
