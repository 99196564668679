import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import AddTeacherTable from './addTeacherTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../redux/formReducer.js';
import { notifySuccess, notifyError } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CREATE_TEACHER_MUTATION } from '../../../helpers/api/Mutation.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js';
import specializationOptions from "../../../data/teachersSpecialization.json"
import { GET_ALL_TEACHER_QUERY } from '../../../helpers/api/Queries.js';
import { setTeacherTableData, setIsLoading } from '../../../redux/common_reducer.js';

const AddTeacher = () => {
    const dispatch = useDispatch();
    const schools = useSelector((state) => state.common.schools)
    const teacherData = useSelector((state) => state.form.addTeacher);
    const [errors, setErrors] = useState({});
    const [selectedSchool, setSelectedSchool] = useState(null);
    const isLoading = useSelector((state) => state.common.isLoading);

    const handleChange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectSchool') {
            dispatch(updateForm({ formName: 'addTeacher', formData: { specialization: null, selectSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'addTeacher', formData: { [fieldName]: selectedOption } }));
        }
    };

    // Validate the form if the field is empty and validate regex

    const validateForm = () => {
        const requiredFields = ['selectSchool', 'teacherName', 'specialization', 'phoneNumber', 'emailID'];
        const newErrors = {};

        // Validation for all required fields
        requiredFields.forEach((field) => {
            if (!teacherData[field] || (Array.isArray(teacherData[field]) && !teacherData[field].length)) {
                newErrors[field] = `${field === 'selectSchool' ? 'School' : field} is required.`;
            } else {
                newErrors[field] = '';
            }
        });


        // Parent name validation
        if (teacherData.teacherName) {
            if (!/^[a-zA-Z. ]+$/.test(teacherData.teacherName)) {
                newErrors.teacherName = 'Parent name should be valid alphabets with space and dot(.) only';
                notifyError("Parent name should be valid alphabets with space and dot(.) only")
            } else {
                newErrors.teacherName = '';
            }
        }

        // Phone number validation
        if (teacherData.phoneNumber) {
            if (!/^\d{10}$/.test(teacherData.phoneNumber)) {
                newErrors.phoneNumber = 'Invalid phone number. Please enter a 10-digit number.';
                notifyError("Invalid phone number. Please enter a 10-digit number.")
            } else {
                newErrors.phoneNumber = '';
            }
        }
        // Email validation
        if (teacherData.emailID) {
            if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(teacherData.emailID)) {
                newErrors.emailID = 'Invalid email address.';
                notifyError("Invalid email address.")

            } else {
                newErrors.emailID = '';
            }
        }


        const emptyFields = requiredFields.filter((field) => !teacherData[field] || (Array.isArray(teacherData[field]) && !teacherData[field].length));
        if (emptyFields.length > 0) {
            notifyError('All fields are required');
        }
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    // clear the all form input and errors
    const handleClear = () => {
        dispatch(clearForm({ formName: 'addTeacher' }));
        setErrors({});
        notifySuccess("Form cleared successfully")

    };

    // After validate the form fields post the data to backend
    const handleSave = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            dispatch(setIsLoading(true));
            const selectedSchoolValue = teacherData.selectSchool.value;
            const selectedSpecializationValue = teacherData.specialization.value;
            try {
                const response = await MutateRequest(CREATE_TEACHER_MUTATION, {
                    data: {
                        schoolId: selectedSchoolValue,
                        name: teacherData.teacherName,
                        email: teacherData.emailID,
                        mobileNumber: teacherData.phoneNumber,
                        specialization: selectedSpecializationValue,

                    },
                });
                const responseStatus = response.data.createOneTeacher.status
                if (responseStatus === "Success") {
                    const createdTeacher = teacherData.teacherName;
                    notifySuccess(`Teacher "${createdTeacher}" created successfully`);
                    //############ Reload the teacher table as new teacher  got added successfully 
                    try {
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        const response = await QueryRequest(GET_ALL_TEACHER_QUERY, { schoolId: selectedSchool.value });
                        dispatch(setTeacherTableData(response?.data?.getAllTeachers?.items))
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                    // Dispatch an action to clear form and errors
                    dispatch(clearForm({ formName: 'addTeacher' }));
                    setErrors({});
                } else if (responseStatus === "Failure") {
                    notifyError(response.data.createOneTeacher.error.message)
                }

            } catch (error) {
                console.error('Error creating school:', error);
                notifyError('An error occurred while saving. Please try again.');
            } finally {
                dispatch(setIsLoading(false));
            }
        }
    };

    return (
        <div>
            <div>
                <ToastContainer />

            </div>
            <div className='flex flex-row justify-between mb-6'>
                <h2 className="text-2xl font-bold">Add Teacher</h2>

            </div>

            <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form >


                    <h2 className=' text-xl text-black font-bold mb-3'>Teacher Contact Detail: </h2>
                    <div className="mb-4 md:flex md:items-center">


                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectSchool ? 'has-error' : ''}`}>
                            <label htmlFor="selectSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {errors.selectSchool && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.selectSchool ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schools}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={teacherData.selectSchool}
                                    onChange={(selectedOption) => handleChange('selectSchool', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>




                        <div className="md:w-1/4 me-4">
                            <label htmlFor="teacherName" className="block text-gray-700 text-sm font-bold mb-4">
                                Teacher’s name
                                {errors.teacherName && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <input
                                type="text"
                                id="teacherName"
                                name="teacherName"
                                className={`w-full border p-2 rounded ${errors.teacherName ? 'border-red-500' : ''}`}
                                value={teacherData.teacherName}
                                onChange={(e) => handleChange('teacherName', e.target.value)}
                            />
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.specialization ? 'has-error' : ''}`}>
                            <label htmlFor="specialization" className="block text-gray-700 text-sm font-bold mb-4">
                                Specialization
                                {errors.specialization && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.specialization ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={specializationOptions}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={teacherData.specialization}
                                    onChange={(selectedOption) => handleChange('specialization', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-4">
                                Phone Number
                                {errors.phoneNumber && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                maxLength={10}
                                className={`w-full border p-2 rounded ${errors.phoneNumber ? 'border-red-500' : ''}`}
                                value={teacherData.phoneNumber}
                                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                            />
                            {/* {errors.phoneNumber && <p className='text-red-500'>{errors.phoneNumber}</p>} */}
                        </div>
                    </div>



                    <div className="mb-4 md:flex md:items-center">

                        <div className="md:w-1/4 me-4">
                            <label htmlFor="emailID" className="block text-gray-700 text-sm font-bold mb-4">
                                Email ID
                                {errors.emailID && <span className='text-red-500 pl-2 '>*</span>}

                            </label>
                            <input
                                type="email"
                                id="emailID"
                                name="emailID"
                                className={`w-full border p-2 rounded ${errors.emailID ? 'border-red-500' : ''}`}
                                value={teacherData.emailID}
                                onChange={(e) => handleChange('emailID', e.target.value)}
                            />
                            {/* {errors.emailID && <p className='text-red-500'>{errors.emailID}</p>} */}

                        </div>




                    </div>

                    <div className='flex flex-row space-x-5 justify-end'>
                        <button
                            type="button"
                            onClick={handleClear}
                            className=" lg:px-16 lg:py-2 md:px-10 md:py-0.5 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                            <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                        </button>
                        <button
                            type="submit"
                            onClick={handleSave}
                            className="lg:px-16 lg:py-2 md:py-0.5 md:px-10 sm:px-10 sm:py-1 px-6 py-2 border border-blue-900 text-white bg-blue-900  rounded transition-all ">
                            {isLoading ? (
                                <div className="flex items-center justify-center w-full">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                </div>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faSave} /> Save
                                </>
                            )}
                        </button>

                    </div>
                    <div className='text-red-500 mb-4'>
                        {/* {Object.values(errors).some((error) => error) && 'All fields are required.'} */}
                    </div>
                </form >
                {/* END OF FORM */}
                < hr className="border-t border-gray-300 my-8 mb-28" />


                < AddTeacherTable selectedSchool={selectedSchool} />

            </div >

        </div >
    );
};

export default AddTeacher