import * as React from "react"
import { useMemo, useState, useEffect } from 'react';
import {
    // MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import { Button, Box, IconButton, } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { notifyWarning, notifySuccess } from '../../../common/notifications.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import { SEND_SMS_TO_PARENT, CANCEL_SMS_MUTATION } from "../../../helpers/api/Mutation.js";
import { MutateRequest } from "../../../helpers/api/service_helper.js";

const Example = ({ tableData }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState(tableData);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {
        setIsLoading(true);
        setData(tableData);
        setIsLoading(false);
    }, [tableData]);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'S No',
                size: 50,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.id,
                    helperText: validationErrors?.id,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            id: undefined,
                        }),
                },
            },
            {
                accessorKey: 'parent.name',
                header: 'Parent Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.parentName,
                    helperText: validationErrors?.parentName,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            parentName: undefined,
                        }),
                },
            },
            {
                accessorKey: 'parent.mobileNumber',
                header: 'Parent Phone No',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.mobileNumber,
                    helperText: validationErrors?.mobileNumber,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            mobileNumber: undefined,
                        }),
                },
            },
            {
                accessorKey: 'smsKidInfo.className',
                header: 'Class',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.Class,
                    helperText: validationErrors?.Class,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            Class: undefined,
                        }),
                },
            },
            {
                accessorKey: 'smsKidInfo.kidsName',
                header: 'Student Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.Studentname,
                    helperText: validationErrors?.Studentname,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            Studentname: undefined,
                        }),
                },
            },
            {
                accessorKey: 'smsType',
                header: 'SMS Type',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.smsType,
                    helperText: validationErrors?.smsType,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            smsType: undefined,
                        }),
                },
            }, {
                accessorKey: 'sentAt',
                header: 'Sent at',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.sentAt,
                    helperText: validationErrors?.sentAt,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            sentAt: undefined,
                        }),
                },
                Cell: ({ row }) => {
                    const data = row.original.sentAt
                    let text = '';

                    if (data === null) {
                        text = '—';
                    }
                    else {
                        text = data
                    }
                    return (
                        <span>{text}</span>
                    );

                },
            },
            {
                accessorKey: 'deliveryStatus',
                header: 'Delivered',
                size: 50,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.delivered,
                    helperText: validationErrors?.delivered,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            delivered: undefined,
                        }),
                },
            },
        ],
        [validationErrors],
    );


    //UPDATE action
    // const handleSaveUser = async ({ values, table }) => {
    //     try {
    //         setIsUpdatingUser(true);

    //         const userIndex = data.findIndex((row) => row.id === values.id);

    //         if (userIndex === -1) {
    //             console.error('Student not found in the data array.');
    //             return;
    //         }
    //         const newData = [...data];
    //         newData[userIndex] = { ...newData[userIndex], ...values };
    //         setData(newData);

    //         notifySuccess('The Student has been successfully edited');

    //         await new Promise((resolve) => setTimeout(resolve, 1000));

    //         // Close the editing modal
    //         table.setEditingRow(null);
    //     } catch (error) {
    //         console.error('Error updating user:', error);
    //     } finally {
    //         setIsUpdatingUser(false);
    //     }
    // };

    // // TODO
    // const deleteUser = async ({ values, table }) => {
    //     try {
    //         setIsDeletingUser(true);

    //         const userId = values.id;

    //         if (!userId) {
    //             console.error('Student ID is missing or undefined.');
    //             return;
    //         }
    //         const newData = data.filter((row) => row.id !== userId);
    //         setData(newData);
    //         notifySuccess('The Student has been successfully deleted');
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //     } catch (error) {
    //         console.error('Error deleting user:', error);
    //     } finally {
    //         setIsDeletingUser(false);
    //     }
    // };

    // TODO

    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this Student?')) {
    //         deleteUser({ values: row, table });
    //     }
    // };

    const handleExportData = () => {
        // If there is no data, notify a warning
        if (!data || data.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Auto-size columns
        const colWidths = [];
        Object.keys(data[0]).forEach(key => {
            colWidths.push({ wch: key.length + 2 }); // Add some padding
        });
        worksheet['!cols'] = colWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Save the XLSX file
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'Bulkupload.xlsx');
    };

    const handleModalToggle = (row) => {
        setSelectedRowData(row); // Set selected row data
        setIsModalOpen(!isModalOpen); // Toggle modal
    };

    const handleCancelSMS = async (rows) => {
        setIsLoading(true);
        try {
            const rowData = rows.map((row) => row.original);
            const requestData = rowData.map(item => ({
                id: item.id,
                message: item.message,
                mobileNumber: item.parent.mobileNumber
            }));

            const response = await MutateRequest(CANCEL_SMS_MUTATION, { data: { sendSMSData: requestData } });

            // Map over items in response data, check if cancelled === "Yes", and update deliveryStatus accordingly
            const updatedData = data.map(row => {
                const updatedItem = response.data.cancelSMS.items.find(item => item.id === row.id);
                if (updatedItem && updatedItem.cancelled === "Yes") {
                    return {
                        ...row,
                        deliveryStatus: "Cancelled"
                    };
                }
                return row;
            });

            // Update the state with the modified data
            setData(updatedData);
            // Reload the table with updated data
            setRowSelection(false);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (error) {
            console.error("Error occurred:", error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleSentSMS = async (rows) => {
        setIsLoading(true);

        try {
            const rowData = rows.map((row) => row.original);

            let items = [];
            const requestData = rowData.map(item => {
                return {
                    id: item.id,
                    message: item.message,
                    mobileNumber: item.parent.mobileNumber
                };
            });
            items.push(requestData);

            const response = await MutateRequest(SEND_SMS_TO_PARENT, { data: { sendSMSData: requestData } });

            notifySuccess('SMS has been sent successfully'); // Display success notification

            // Here's the modification data to reload the table
            const updatedData = data.map(row => {
                const item = response.data.sendSMSToParent.items.find(item => item.id === row.id);
                if (item) {
                    return {
                        ...row,
                        deliveryStatus: item.deliveryStatus,
                        sentAt: item.sentAt
                    };
                }
                return row;
            });
            setData(updatedData);
            setRowSelection(false)
            await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (error) {
            console.error("Error occurred:", error);
        } finally {
            setIsLoading(false);
        }
    };



    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };


    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: (row) => row.original.deliveryStatus === "Pending" || row.original.deliveryStatus === "Failed",
        onRowSelectionChange: setRowSelection,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Message',
            },
        },
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        initialState: { columnVisibility: { id: false, content: false } },
        getRowId: (row) => row.id,
        // muiToolbarAlertBannerProps: isLoading
        //     ? {
        //         color: 'error',
        //         children: 'Error loading data',
        //     }
        //     : undefined,
        // muiTableContainerProps: {
        //     sx: {
        //         minHeight: '500px',
        //     },
        // },
        // onCreatingRowSave: handleCreateUser,
        // onEditingRowCancel: () => setValidationErrors({}),
        // onEditingRowSave: handleSaveUser,
        enableRowActions: true,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton
                    color="default"
                    onClick={() => handleModalToggle(row.original)}
                >
                    <VisibilityIcon />
                </IconButton>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className='flex' sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    sx={{ backgroundColor: 'rgb(30 58 138)', mr: 1 }}
                >
                    Export All Data
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    type="submit"
                    variant="contained"
                    onClick={() => handleSentSMS(table.getSelectedRowModel().rows)}
                    sx={{ backgroundColor: 'rgb(30 58 138)', mr: 1 }}
                    startIcon={<MessageOutlinedIcon />}
                >
                    Send SMS
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    type="submit"
                    variant="contained"
                    onClick={() => handleCancelSMS(table.getSelectedRowModel().rows)}
                    sx={{ backgroundColor: 'rgb(30 58 138)', mr: 1 }}
                    startIcon={<ClearIcon />}
                >
                    Cancel SMS
                </Button>
            </div>

        ),

        state: {
            isLoading: isLoading,
            isSaving: isUpdatingUser || isDeletingUser,
            rowSelection
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });
    //do something when the row selection changes...
    useEffect(() => {
        // console.info({ rowSelection }); //read your managed row selection state
        // console.info(table.getState().rowSelection); //alternate way to get the row selection state
    }, [rowSelection]);

    return (
        <>
            <MaterialReactTable table={table} />
            <Modal open={isModalOpen} setOpen={setIsModalOpen} rowData={selectedRowData} />
        </>
    )
        ;
};

function Modal({ open, setOpen, rowData }) {
    const buttonStyle = {
        border: '2px solid #1E40AF',
        color: '#FFFFFF',
        backgroundColor: '#1E40AF',
        borderRadius: '5px',
        transition: 'all 0.3s',
        cursor: 'pointer',
    };

    let modalContent = null;

    // Check if rowData exists
    if (rowData) {
        // If rowData exists, assign modalContent
        modalContent = rowData.message;
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {modalContent}
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        onClick={() => setOpen(false)}
                        style={buttonStyle}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}



const SendSMStable = ({ tableData }) => (
    <Example tableData={tableData} />
);

export default SendSMStable;

// const validateRequired = (value) => !!value.length;
// const validatePhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);
// const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// function validateTeacher(user) {
//     return {
//         schoolID: !validateRequired(user.schoolID)
//             ? 'School ID is Required'
//             : '',
//         teacherName: !validateRequired(user.teacherName)
//             ? 'Teacher Name is Required'
//             : '',
//         teacherSpecialization: !validateRequired(user.teacherSpecialization)
//             ? 'Specialization is Required'
//             : '',
//         teacherPhoneNumber: !validateRequired(user.teacherPhoneNumber)
//             ? 'Phone Number is Required'
//             : !validatePhoneNumber(user.teacherPhoneNumber)
//                 ? 'Invalid Phone Number'
//                 : '',
//         teacherEmailID: !validateRequired(user.teacherEmailID)
//             ? 'Email is Required'
//             : !validateEmail(user.teacherEmailID)
//                 ? 'Invalid Email'
//                 : '',
//     };
// }
