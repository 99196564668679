import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import BulkUploadTable from './bulkUploadTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../../redux/formReducer.js';
import { notifySuccess, notifyError, notifyWarning } from "../../../../common/notifications.js"
import 'react-toastify/dist/ReactToastify.css';
import { VALIDATE_PARENT_KID_MUTATION, GET_CLASS_DROPDOWN_QUERY } from '../../../../helpers/api/Queries.js';
import { QueryRequest } from '../../../../helpers/api/service_helper.js';
import { setClassForBulkUpload, setBulkUploadFile, setIsLoading } from '../../../../redux/common_reducer.js';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import * as XLSX from 'xlsx';
import NotifyModal from '../../../../common/notifyModal.js';
import excelTemplate from "../../../../resource/template.xlsx"
import { getItemFromLocalStorage } from '../../../../helpers/api/utils.js';


const BulkUpload = () => {
    const dispatch = useDispatch();
    const bulkUpload = useSelector((state) => state.form.bulkUpload);
    const [errors, setErrors] = useState({});
    const [selectedSchool, setSelectedSchool] = useState(null);
    const schools = useSelector((state) => state.common.schools);
    const classes = useSelector((state) => state.common.classForBulkUpload);
    const bulkUploadFile = useSelector((state) => state.common.bulkUploadFile);
    const [showTable, setShowTable] = useState(false);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [dataArray, setDataArray] = useState([]);
    const isLoading = useSelector((state) => state.common.isLoading);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedSchool) {
                    // before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
                    // this condition is retrieve students based on the loged in user role
                    const teacherIdGet = getItemFromLocalStorage('teacher_id');
                    const queryParam = {};
                    if (teacherIdGet) {
                        queryParam.teacherId = teacherIdGet.id
                    } else {
                        queryParam.schoolId = selectedSchool.value
                    }
                    const responseClass = await QueryRequest(GET_CLASS_DROPDOWN_QUERY, queryParam);
                    const fetchedClass = responseClass.data.getClazzDropDownOptions.options;

                    const schoolsForClassToSet = fetchedClass.map((clazz) => ({
                        value: clazz.value,
                        label: clazz.label,
                    }));
                    dispatch(setClassForBulkUpload(schoolsForClassToSet))
                }
            } catch (error) {
                notifyError(`${error}`);
                console.error(error)
            }
        };

        fetchData();
    }, [selectedSchool, dispatch]);

    // Function to validate the form
    const validateForm = () => {
        // List of required form fields
        const requiredFields = ['selectedSchool', 'selectedStandard', 'bulkUploadFile'];
        // Object to store validation errors
        const newErrors = {};

        // Loop through each required field
        requiredFields.forEach((field) => {
            if (field === 'bulkUploadFile') {
                // Validate file upload field
                if (!dataArray || dataArray.length === 0) {
                    newErrors[field] = 'File upload is required.';
                } else {
                    newErrors[field] = '';
                }
            } else {
                // Validate other fields
                if (!bulkUpload[field] || (Array.isArray(bulkUpload[field]) && !bulkUpload[field].length)) {
                    newErrors[field] = 'This field is required.';
                } else {
                    newErrors[field] = '';
                }
            }
        });

        // Filter empty fields
        const emptyFields = requiredFields.filter((field) => {
            if (field === 'bulkUploadFile') {
                return !dataArray || dataArray.length === 0;
            }
            return !bulkUpload[field] || (Array.isArray(bulkUpload[field]) && !bulkUpload[field].length);
        });

        // Notify if there are empty fields
        if (emptyFields.length > 0) {
            notifyError("All fields are required");
        }

        // Set validation errors
        setErrors(newErrors);

        // Check if there are no validation errors
        return Object.values(newErrors).every((error) => !error);
    };




    const handleFieldChange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectedSchool') {
            dispatch(updateForm({ formName: 'bulkUpload', formData: { selectedStandard: null, selectedSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'bulkUpload', formData: { [fieldName]: selectedOption } }));
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setErrors({ ...errors, bulkUploadFile: '' });
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                // Check if the workbook contains the expected sheet

                if (workbook.SheetNames.length < 2) {
                    notifyError("Please use a template to download an Excel sheet");
                    return;
                }

                const sheetName = workbook.SheetNames[1];
                const sheet = workbook.Sheets[sheetName];
                // Convert the sheet to an array of objects
                const sheetArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (sheetArray.length <= 1) {
                    notifyError("Please enter data to read the file");
                    return;
                }

                // Get the headers
                const dataRows = sheetArray.slice(1);
                const items = [];
                for (let row of dataRows) {
                    if (row[0] !== undefined && row[0] !== '') {
                        const kids = [];
                        const kid = {
                            studentName: (row[4] && row[4] !== "empty") ? row[4].toString().trim() : "",
                            gender: (row[5] && row[5] !== "empty") ? row[5].toString().trim() : "",
                            dob: (row[6] && row[6] !== "empty") ? row[6].toString().trim() : "",
                            clazzId: bulkUpload.selectedStandard.value.toString().trim()
                        };
                        kids.push(kid);
                        const item = {
                            sno: !isNaN(row[0] || row[6] !== "empty") ? parseInt(row[0]) : 0,
                            parentName: (row[1] && row[1] !== "empty") ? row[1].toString().trim() : "",
                            mobileNumber: (row[2] && row[2] !== "empty") ? row[2].toString().trim() : "",
                            pincode: (row[3] && row[3] !== "empty") ? row[3].toString().trim() : "",
                            kids: kids
                        };
                        items.push(item);
                    }
                    setDataArray(items);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };


    const handleClear = () => {
        const formElement = document.getElementById('bulkUploadForm');
        formElement.reset();
        dispatch(clearForm({ formName: 'bulkUpload' }));
        setErrors({});
        setDataArray([]);
        notifySuccess("Form cleared successfully");
    };

    const handlePreview = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            dispatch(setIsLoading(true));
            try {
                const inputData = {
                    data: { items: dataArray }
                };
                const response = await QueryRequest(VALIDATE_PARENT_KID_MUTATION, inputData);
                // Reset the form state
                const formElement = document.getElementById('bulkUploadForm');
                formElement.reset();
                dispatch(clearForm({ formName: 'bulkUpload' }));
                setErrors({});
                setDataArray([]);
                // set response data  for bulk upload 
                dispatch(setBulkUploadFile(response.data.validateCreateParentKid));
                // set warning message before submmiting
                notifyWarning("Please ensure the table data before submitting");
                setShowTable(true);
                setHasValidationErrors(response.data.validateCreateParentKid.isInputListHasValidationErrors);
                if (!response.data.validateCreateParentKid.isInputListHasValidationErrors) {
                    setModalOpen(true)

                } else {
                    setModalOpen(true)

                }
            } catch (error) {
                console.error('Error creating Class:', error);
                notifyError('An error occurred while saving. Please try again.');
            }
            finally {
                dispatch(setIsLoading(false));
            }
        };
    };


    const handleDownloadExcel = () => {
        const templateFilePath = excelTemplate;
        const a = document.createElement('a');
        a.href = templateFilePath;
        a.download = 'ukiddiy-student-bulkupload.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };




    return (
        <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
            <div>
                <NotifyModal open={modalOpen} setOpen={setModalOpen} hasValidationErrors={hasValidationErrors} />
            </div>
            <form id="bulkUploadForm">
                <div className='flex justify-between'>
                    <h2 className='text-xl text-black font-bold mb-3'>Bulk Upload</h2>
                    <div className='flex items-center'>
                        <p className='mr-2 text-blue-500 cursor-pointer underline' onClick={handleDownloadExcel}>Download Excel Template</p>
                        <Tooltip title={<span style={{ fontSize: 14 }}>Use this excel template for bulk upload</span>} placement="bottom-end" arrow>
                            <InfoIcon style={{ marginLeft: 4 }} />
                        </Tooltip>
                    </div>
                </div>
                <div className="mb-4 md:flex md:items-center">

                    <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectedSchool ? 'has-error' : ''}`}>
                        <label htmlFor="selectedSchool" className="block text-gray-700 text-sm font-bold mb-4">
                            Select School
                            {errors.selectedSchool && <span className='text-red-500 pl-2'>*</span>}

                        </label>
                        <div className={`w-full border rounded ${errors.selectedSchool ? 'border-red-500' : 'border-none'}`}>
                            <Select
                                options={schools}
                                isSearchable
                                isClearable
                                components={makeAnimated()}
                                value={bulkUpload.selectedSchool}
                                onChange={(selectedOption) => handleFieldChange('selectedSchool', selectedOption)}
                                className='z-50'
                            />
                        </div>
                    </div>



                    <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectedStandard ? 'has-error' : ''}`}>
                        <label htmlFor="selectedStandard" className="block text-gray-700 text-sm font-bold mb-4">
                            Select Standard
                            {errors.selectedStandard && <span className='text-red-500 pl-2'>*</span>}

                        </label>
                        <div className={`w-full border rounded ${errors.selectedStandard ? 'border-red-500' : 'border-none'}`}>
                            <Select
                                options={classes}
                                isSearchable
                                isClearable
                                components={makeAnimated()}
                                value={bulkUpload.selectedStandard}
                                onChange={(selectedOption) => handleFieldChange('selectedStandard', selectedOption)}
                                className='z-50'
                            />
                        </div>
                    </div>

                </div>
                <div>
                    <div className="mb-4 md:flex md:items-center">
                        <div className="md:w-1/5 mb-4 md:mb-0 me-4">
                            <label htmlFor="fileUpload" className="block text-gray-700 text-sm font-bold mb-4">
                                Choose a file to upload
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: 14 }}>
                                            Select the school and Standard to enable file upload
                                        </span>
                                    }
                                    placement="bottom-end"
                                    arrow
                                >
                                    <InfoIcon style={{ marginLeft: 8, color: "black" }} />
                                </Tooltip>

                                {errors.bulkUploadFile && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`flex justify-center items-center ps-6 p-2 py-6 border-dashed border-2 ${errors.bulkUploadFile ? 'border-red-500' : ''}`}>
                                <input type="file" accept=".xlsx" disabled={!bulkUpload.selectedStandard} onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='flex mt-10 mb-10 flex-row space-x-5 justify-end'>

                    <button
                        type="button"
                        onClick={handleClear}
                        className=" lg:px-16 lg:py-2 md:px-10 md:py-0.5 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                        <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                    </button>
                    <button
                        type="submit"
                        onClick={handlePreview}
                        className="lg:px-16 lg:py-2 md:py-0.5 md:px-10 sm:px-10 sm:py-1 px-6 py-2 border border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">
                        {isLoading ? (
                            <div className="flex items-center justify-center w-full">
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                            </div>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faEye} /> Preview
                            </>
                        )}
                    </button>

                </div>

            </form >
            {/* END OF FORM */}
            < hr className="border-t border-gray-300 my-8 " />
            {showTable && <BulkUploadTable selectedSchool={selectedSchool} setShowTable={setShowTable} />}
        </div >
    )
}

export default BulkUpload