import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeAnimated from 'react-select/animated';
import { Calendar } from 'react-feather';
import ProgressTable from './ProgressTable.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm, clearFormAll } from '../../../redux/formReducer.js';
import Select from 'react-select';
import { notifyError } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setClasses, setIsLoading } from '../../../redux/common_reducer.js';
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';
import { QueryRequest } from '../../../helpers/api/service_helper.js';
import { GET_CLASS_DROPDOWN_QUERY, GET_PROGRESS_REPORT } from '../../../helpers/api/Queries.js';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import PieChartProgress from './PieChartProgress.js';
import ChartReportForm from './ChartReport/ChartReportForm.js';

const ProgressReport = () => {

    const dispatch = useDispatch();
    const filterProgress = useSelector((state) => state.form.progressReport);
    const fromSelectedDate = useSelector((state) => state.form.progressReport.fromdate);
    const toSelectedDate = useSelector((state) => state.form.progressReport.todate);
    const isLoading = useSelector((state) => state.common.isLoading);
    const schools = useSelector((state) => state.common.schools)
    const [selectedSchool, setSelectedSchool] = useState(null);
    const classes = useSelector((state) => state.common.classes);
    const [tableData, setTableData] = useState([])
    const [errors, setErrors] = React.useState({});
    const [showTable, setShowTable] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);

    const [uploadMode, setUploadMode] = useState('Report');

    const fetchClasses = useCallback(async (selectedSchoolValue) => {
        try {
            // before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
            // this condition is retrieve students based on the loged in user role
            const teacherIdGet = getItemFromLocalStorage('teacher_id');
            const queryParam = {};
            if (teacherIdGet) {
                queryParam.teacherId = teacherIdGet.id;
            } else {
                queryParam.schoolId = selectedSchoolValue;
            }
            const responseClass = await QueryRequest(GET_CLASS_DROPDOWN_QUERY, queryParam);
            const fetchedClass = responseClass.data.getClazzDropDownOptions.options;

            dispatch(setClasses(
                fetchedClass.map((clazz) => ({
                    value: clazz.value,
                    label: clazz.label,
                }))
            ));
        } catch (error) {
            notifyError(`${error}`);
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedSchool) {
            fetchClasses(selectedSchool.value);
        }
    }, [selectedSchool, fetchClasses]);

    useEffect(() => {
        if (showTable) {
            setTableLoading(true);
            setTimeout(() => {
                setTableLoading(false);
            }, 1000);
        }
    }, [showTable]);

    useEffect(() => {
        if (showChart) {
            setChartLoading(true);
            setTimeout(() => {
                setChartLoading(false);
            }, 1000);
        }
    }, [showChart]);

    const handleClear = () => {
        dispatch(clearForm({ formName: 'progressReport', formName: 'progressReportChart' }));
        setSelectedSchool(null);
        setErrors({});
        setShowTable(false);
        setShowChart(false);
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate school field
        if (!filterProgress.selectSchool) {
            newErrors.selectSchool = 'School is required';
        }

        // Validate from date
        if (!filterProgress.fromdate) {
            newErrors.fromdate = 'From Date is required';
        }

        // Validate to date
        if (!filterProgress.todate) {
            newErrors.todate = 'To Date is required';
        }

        // Validate report type
        if (!filterProgress.reportType) {
            newErrors.reportType = 'Report Type is required';
        }   // Validate the reportType value is class and the class is mandatory
        else if (filterProgress.reportType.value === 'Class' && !filterProgress.class) {
            newErrors.class = 'Class is required when Report Type is Class';
        }

        // Combine error messages
        let errorMessage = '';
        Object.values(newErrors).forEach((error) => {
            errorMessage += `${error}, `;
        });

        // Remove trailing comma and space
        errorMessage = errorMessage.slice(0, -2);

        // Show single notifyError if there are any errors
        if (errorMessage !== '') {
            notifyError(`${errorMessage}`);
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleView = async () => {
        if (validateForm()) {
            const data = {
                schoolId: filterProgress.selectSchool.value,
                classId: filterProgress.class ? filterProgress.class.value : null,
                fromDate: filterProgress.fromdate,
                toDate: filterProgress.todate,
            };
            try {
                dispatch(setIsLoading(true));
                const response = await QueryRequest(GET_PROGRESS_REPORT, data);
                setTableData(response.data.getKidProgressReport.items);

                if (filterProgress.reportType.value !== 'Class') {
                    setShowTable(true);
                    setShowChart(false);
                } else {
                    setShowTable(false);
                    setShowChart(true);
                }
                // Clear the form state
                // dispatch(clearForm({ formName: 'progressReport' }));
                // setSelectedSchool(null);
                // setErrors({});

            } catch (error) {
                console.error(error)
                notifyError(`${error}`);
            } finally {
                dispatch(setIsLoading(false));
            }

        }
    };



    const handlechange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectSchool') {
            dispatch(updateForm({ formName: 'progressReport', formData: { class: null, selectSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'progressReport', formData: { [fieldName]: selectedOption } }));
        }
    };

    const handleDate = (fieldName, date) => {
        // Get the DOB in this format DD/MM/YYYY
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;

        // Update the form data directly within the progressReport slice
        dispatch(updateForm({
            formName: 'progressReport',
            formData: {
                [fieldName]: formattedDate,
            },
        }));
    };

    const toggleUploadMode = (mode) => {
        setUploadMode(mode);
        handleClear()
    };


    const reportType = [
        { value: 'Individual', label: 'Individual' },
        { value: 'Class', label: 'Class' },
    ];


    return (
        <div>
            <div>
                <ToastContainer />
            </div>
            <h2 className="text-2xl font-bold mb-6">Progress Report</h2>
            {/*<button onClick={() => toggleUploadMode('Report')} className={`p-2 px-8 border-blue-900 ${uploadMode === 'Report' ? 'bg-blue-900 text-white' : ''}`}>
                Report
            </button>
            <button onClick={() => toggleUploadMode('Chart Report')} className={`p-2 px-8 border-blue-900 ${uploadMode === 'Chart Report' ? 'bg-blue-900 text-white' : ''}`}>
                Charts
            </button> */}
            {/* <hr className='mb-3' /> */}

            {uploadMode === 'Report' && <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form className='mb-20' >
                    <h2 className=' text-xl text-black font-bold mb-3'> Progress Details:</h2>

                    <div className="mb-4 md:flex md:items-center">
                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectSchool ? 'has-error' : ''}`}>
                            <label htmlFor="selectSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {errors.selectSchool ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectSchool ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schools}
                                    isSearchable
                                    isClearable
                                    id="selectSchool"
                                    components={makeAnimated()}
                                    value={selectedSchool}
                                    className='z-50'
                                    onChange={(selectedOption) => handlechange('selectSchool', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.reportType ? 'has-error' : ''}`}>
                            <label htmlFor="reportType" className="block text-gray-700 text-sm font-bold mb-4">
                                Report Type
                                {errors.reportType ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.reportType ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={reportType}
                                    isClearable
                                    components={makeAnimated()}
                                    id="reportType"
                                    name="reportType"
                                    value={filterProgress.reportType}
                                    onChange={(selectedOption) => handlechange('reportType', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className=" me-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                From Date
                                {errors.fromdate ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <DatePicker
                                selected={fromSelectedDate ? moment(fromSelectedDate, 'YYYY-MM-DD').toDate() : null}
                                onChange={(date) => handleDate('fromdate', date)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="  Select from date"
                                icon={<Calendar size={20} />}
                                showIcon
                                className={`border rounded z-50  ${errors.fromdate ? 'border-red-500' : 'border'}`}
                                maxDate={new Date()}
                                minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())}
                            />
                        </div>

                        <div className=" me-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                To Date
                                {errors.todate ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}
                            </label>
                            <DatePicker
                                selected={toSelectedDate ? moment(toSelectedDate, 'YYYY-MM-DD').toDate() : null}
                                onChange={(date) => handleDate('todate', date)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="  Select to date"
                                icon={<Calendar size={20} />}
                                showIcon
                                className={`border rounded z-50  ${errors.todate ? 'border-red-500' : 'border'}`}
                                maxDate={new Date()}
                                minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())}
                            />
                        </div>
                    </div>

                    <div className="mb-4 md:flex md:items-center">
                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.class ? 'has-error' : ''}`}>
                            <label htmlFor="class" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Class
                                {errors.class && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.class ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={classes}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id="class"
                                    name="class"
                                    value={filterProgress.class}
                                    onChange={(selectedOption) => handlechange('class', selectedOption)}
                                />
                            </div>
                        </div>
                    </div>



                    {/* {errors && <div className="text-red-500">{errors}</div>} */}
                </form >


                {/* END OF FORM */}
                <div className="flex mb-28 flex-col md:flex-row justify-end mt-4 space-y-2 md:space-y-0 md:space-x-2">

                    <button
                        type="button"
                        onClick={handleClear}
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                        <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                    </button>
                    <button
                        type="submit"
                        onClick={handleView}
                        disabled={isLoading} // Disable button when loading
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">
                        <FontAwesomeIcon icon={faEye} />
                        {isLoading ? 'Viewing...' : 'View'}
                    </button>

                </div>
                <hr className="border-t border-gray-300 my-8" />
                <div>
                    {showTable && (
                        <>
                            {tableLoading ? (
                                <div className="text-center">
                                    <CircularProgress color="success" />

                                </div>
                            ) : (
                                <ProgressTable tableData={tableData} />
                            )}
                        </>
                    )}
                    {showChart && (
                        <>
                            {chartLoading ? (
                                <div className="text-center">
                                    <CircularProgress color="success" />
                                </div>
                            ) : (
                                <>
                                    <PieChartProgress tableData={tableData} />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            }
            {uploadMode === 'Chart Report' &&
                <>
                    <ChartReportForm />
                </>
            }
        </div >
    );
};

export default ProgressReport