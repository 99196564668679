import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/navbar';
import AddStudentForm from './components/addStudent/AddStudentForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader, faSignOutAlt, faSchool, faChalkboardTeacher, faPersonChalkboard, faUserGraduate, faFileAlt ,faMessage} from '@fortawesome/free-solid-svg-icons';
import ProgressReport from './components/progressReport/ProgressReportForm';
import AddSchool from "./components/addSchool/AddSchool"
import AddTeacher from "./components/addTeacher/AddTeacher"
import AddClass from './components/addClass/AddClass';
import SendSMSform from './components/sendSms/sendSMSform';
import { getItemFromLocalStorage, clearValuesOnLogout } from '../helpers/api/utils';
import { GET_SCHOOL_DROPDOWN_QUERY } from '../helpers/api/Queries';
import { QueryRequest } from '../helpers/api/service_helper';
import { setSchools } from '../redux/common_reducer';
import { notifyError } from '../common/notifications';
import { useDispatch } from 'react-redux';
import { clearFormAll } from '../redux/formReducer';

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [tabBar, setTabBar] = useState(true);
    const [isExpanded, setExpanded] = useState(true);
    const [clickedItemContent, setClickedItemContent] = useState(<ProgressReport />);
    const [clickedItemIndex, setClickedItemIndex] = useState(5);

    const [teacherId, setTeacherId] = useState('');
    const [schoolId, setSchoolId] = useState('');
    const [superAdminId, setSuperAdminId] = useState('');

    useEffect(() => {
        const userId = getItemFromLocalStorage('user_id');
        const token = getItemFromLocalStorage('user_token');
        const teacherIdGet = getItemFromLocalStorage('teacher_id');
        const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
        const schoolIdGet = getItemFromLocalStorage('school_id');

        if (userId && token) {
            setTeacherId(teacherIdGet);
            setSchoolId(schoolIdGet);
            setSuperAdminId(superAdminIdGet)
        } else {
            window.location.reload()
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        // Clean up form state when navigating to another component
        const unsubscribe = () => {
            dispatch(clearFormAll({
                formNames: ['addStudent', 'addClass', 'addTeacher', 'addSchool', 'progressReport', 'bulkUpload', "sendSMS"]
            }));

        };
        return unsubscribe;
    }, [dispatch]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const schoolVariables = {};
                const schoolIdGet = getItemFromLocalStorage('school_id');
                const teacherSchoolIdDrop = getItemFromLocalStorage('teacher_school_id')
                if (teacherSchoolIdDrop) {
                    schoolVariables.schoolId = teacherSchoolIdDrop
                } else if (schoolIdGet) {
                    schoolVariables.schoolId = schoolIdGet.id
                }
                const responseSchool = await QueryRequest(GET_SCHOOL_DROPDOWN_QUERY, schoolVariables);
                const fetchedSchools = responseSchool.data.getSchoolDropDownOptions.options;
                dispatch(setSchools(
                    fetchedSchools.map((school) => ({
                        value: school.value,
                        label: school.label,
                    }))
                ));
            } catch (error) {
                console.error(error)
                notifyError(error);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        document.title = 'uKiddiy - Progress Report';
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    const handlePopstate = () => {
        window.history.pushState(null, null, window.location.pathname);
    };

    const handleItemClick = (content, index, title) => {
        setClickedItemContent(content);
        setClickedItemIndex(index);
        setTabBar(false);
        document.title = `uKiddiy - ${title || 'Home'}`;
        // Clean up form state when navigating to another component
        dispatch(clearFormAll({
            formNames: ['addStudent', 'addClass', 'addTeacher', 'addSchool', 'progressReport', 'bulkUpload', 'sendSMS']
        }));
    };


    const handleLogout = () => {
        clearValuesOnLogout()
        navigate('/login')
    };
    return (
        <>
            <div className="flex flex-col">
                {/* Top Navbar */}
                <Navbar tabBar={tabBar} setTabBar={setTabBar} isExpanded={isExpanded} setExpanded={setExpanded} />

                <div className="flex">
                    {tabBar && (
                        <div className='fixed z-50 h-screen bg-grey' style={{ width: '300px' }}>
                            <div className="shadow-2xl h-full flex flex-col justify-between">
                                <div>
                                    {isExpanded && (
                                        <ul className="list-none">
                                            <div>
                                                <h2 className="bg-blue-900 mt-20 text-white p-4 select-none text-center items-center">
                                                    <FontAwesomeIcon icon={faBookOpenReader} className='text-white pr-4 text-lg' />
                                                    Main Menu
                                                </h2>
                                                {superAdminId &&
                                                    <li
                                                        onClick={() => handleItemClick(<AddSchool />, 0, 'Add School')}
                                                        className={`cursor-pointer ps-4 border-b border-gray-300 py-5 p-2 text-white flex items-center justify-start space-x-2 ${clickedItemIndex === 0 ? 'bg-blue-300' : 'bg-blue-100'}`}>
                                                        <FontAwesomeIcon icon={faSchool} className='text-black' />
                                                        <h2 className='text-black text-center'>School</h2>
                                                    </li>
                                                }

                                                {(superAdminId || schoolId) &&
                                                    <>
                                                        <li
                                                            onClick={() => handleItemClick(<AddTeacher />, 1, 'Add Teacher')}
                                                            className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 text-white flex items-center justify-start space-x-2 ${clickedItemIndex === 1 ? 'bg-blue-300' : 'bg-blue-100'}`} >
                                                            <FontAwesomeIcon icon={faChalkboardTeacher} className='text-black' />
                                                            <h2 className='text-black text-center'>Teacher</h2>
                                                        </li>
                                                        <li
                                                            onClick={() => handleItemClick(<AddClass />, 2, "Add Class")}
                                                            className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 text-white flex items-center justify-start space-x-2 ${clickedItemIndex === 2 ? 'bg-blue-300' : 'bg-blue-100'}`} >
                                                            <FontAwesomeIcon icon={faPersonChalkboard} className='text-black' />
                                                            <h2 className='text-black text-center'>Class</h2>
                                                        </li>
                                                    </>
                                                }
                                                {(superAdminId || schoolId || teacherId) &&
                                                    <>
                                                        <li
                                                            onClick={() => handleItemClick(<AddStudentForm />, 3, "Add Student")}
                                                            className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 text-white flex items-center justify-start space-x-2 ${clickedItemIndex === 3 ? 'bg-blue-300' : 'bg-blue-100'}`} >
                                                            <FontAwesomeIcon icon={faUserGraduate} className='text-black' />
                                                            <h2 className='text-black text-center'>Student</h2>
                                                        </li>
                                                        <li
                                                            onClick={() => handleItemClick(<SendSMSform />, 4, "Send SMS")}
                                                            className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 text-white flex items-center justify-start space-x-2 ${clickedItemIndex === 4 ? 'bg-blue-300' : 'bg-blue-100'}`} >
                                                            <FontAwesomeIcon icon={faMessage} className='text-black' />
                                                            <h2 className='text-black text-center'>SMS</h2>
                                                        </li>
                                                        <li
                                                            onClick={() => handleItemClick(<ProgressReport />, 5, "Progress Report")}
                                                            className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 text-white flex items-center justify-start space-x-2  ${clickedItemIndex === 5 ? 'bg-blue-300' : 'bg-blue-100'}`} >
                                                            <FontAwesomeIcon icon={faFileAlt} className='text-black' />
                                                            <h2 className='text-black text-center'>Progress Report</h2>
                                                        </li>
                                                    </>
                                                }
                                            </div>
                                        </ul>
                                    )}
                                </div>
                                {/* Logout Button */}
                                <ul>
                                    <li
                                        onClick={() => handleLogout()}
                                        className="cursor-pointer  py-2 px-5 mb-4 bg-red-500 text-white flex items-center justify-center space-x-2"
                                    >
                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                        <h2 className='text-center'>Logout</h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className={`flex-1 ${tabBar ? "ml-80" : ""} flex flex-col p-4 overflow-y-auto mt-20`}>
                        {clickedItemContent !== null &&
                            <div className="p-4 mt-2">{clickedItemContent}</div>
                        }
                    </div>
                </div>


            </div>

        </>
    );
};





export default Home;