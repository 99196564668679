import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeAnimated from 'react-select/animated';
import { Calendar } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../redux/formReducer.js';
import Select from 'react-select';
import { notifyError, notifySuccess, notifyWarning } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setClasses, setIsLoading } from '../../../redux/common_reducer.js';
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';
import { QueryRequest } from '../../../helpers/api/service_helper.js';
import { GET_CLASS_DROPDOWN_QUERY, GET_SMS_TABLE_SEARCH } from '../../../helpers/api/Queries.js';
import moment from 'moment';
import SendSMStable from './sendSMStable.js';

const SendSMSform = () => {

    const dispatch = useDispatch();
    const sendSMS = useSelector((state) => state.form.sendSMS);
    const schools = useSelector((state) => state.common.schools)
    const classes = useSelector((state) => state.common.classes);
    const isLoading = useSelector((state) => state.common.isLoading);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [tableData, setTableData] = useState([])
    const [errors, setErrors] = React.useState({});

    const fetchClasses = async (selectedSchoolValue) => {
        try {
            // before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
            // this condition is retrieve students based on the loged in user role
            const teacherIdGet = getItemFromLocalStorage('teacher_id');
            const queryParam = {};
            if (teacherIdGet) {
                queryParam.teacherId = teacherIdGet.id
            } else {
                queryParam.schoolId = selectedSchoolValue
            }
            const responseClass = await QueryRequest(GET_CLASS_DROPDOWN_QUERY, queryParam);
            const fetchedClass = responseClass.data.getClazzDropDownOptions.options;

            dispatch(setClasses(
                fetchedClass.map((clazz) => ({
                    value: clazz.value,
                    label: clazz.label,
                }))
            ));
        } catch (error) {
            notifyError(`${error}`);
        }
    };
    React.useEffect(() => {
        if (selectedSchool) {
            fetchClasses(selectedSchool.value);
        }
    }, [selectedSchool]);

    const handleClear = () => {
        dispatch(clearForm({ formName: 'sendSMS' }));
        setSelectedSchool(null);
        setErrors({});
        setShowTable(false)
    };



    const validateForm = () => {
        const newErrors = {};

        // Validate class field
        if (!sendSMS.selectSchool) {
            newErrors.selectSchool = 'School is mandatory';
            notifyError("School is mandatory")
        }
        const teacherIdGet = getItemFromLocalStorage('teacher_id');
        if (teacherIdGet && !sendSMS.class) {
            newErrors.class = 'Class is mandatory';
            notifyError("Class is mandatory")
        }
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleSearch = async () => {
        if (validateForm()) {
            const data = {
                schoolId: selectedSchool ? selectedSchool.value : null,
                classId: sendSMS.class ? sendSMS.class.value : null,
                smsType: sendSMS.SMStypes ? sendSMS.SMStypes.value : null,
                deliveryStatus: sendSMS.SMSStatus ? sendSMS.SMSStatus.value : null,
            };
            try {
                dispatch(setIsLoading(true));
                const response = await QueryRequest(GET_SMS_TABLE_SEARCH, data);
                setTableData(response.data.getSMSs.items);
                setShowTable(true);
                notifySuccess('The table has been viewed');
                // Clear the form state
                dispatch(clearForm({ formName: 'sendSMS' }));
                setErrors({});
                setSelectedSchool(null);
            } catch (error) {
                console.error(error)
                notifyError(`${error}`);
            } finally {
                dispatch(setIsLoading(false));
            }
        }
    };

    const handlechange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectSchool') {
            dispatch(updateForm({ formName: 'sendSMS', formData: { class: null, selectSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'sendSMS', formData: { [fieldName]: selectedOption } }));
        }
    };


    const SMStypes = [
        { value: 'NewRegistration', label: 'New Registration' },
        { value: 'MobileNoUpdate', label: 'Mobile Number Update' },
        { value: 'AddingNewKid', label: 'Adding New Kid' }
    ];

    const SMSStatus = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Sent', label: 'Sent' },
        { value: 'Failed', label: 'Failed' },
        { value: 'Cancelled', label: 'Cancelled' },
    ]

    return (
        <div>
            <div>
                <ToastContainer />

            </div>
            <h2 className="text-2xl font-bold mb-6">SMS</h2>
            <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form className='mb-20'>
                    {/* <h2 className=' text-xl text-black font-bold mb-3'> SMS Details:</h2> */}

                    <div className="mb-4 md:flex md:items-center">
                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectSchool ? 'has-error' : ''}`}>
                            <label htmlFor="selectSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {errors.selectSchool ? (<span className='text-red-500 pl-2'>*</span>) : <span className='pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.selectSchool ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schools}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={selectedSchool}
                                    className='z-50'
                                    onChange={(selectedOption) => handlechange('selectSchool', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 ${errors.class ? 'has-error' : ''}`}>
                            <label htmlFor={`class`} className="block text-gray-700 text-sm font-bold mb-4">
                                Select Class
                            </label>
                            <div className={`w-full border rounded ${errors.class ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={classes}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id={`class`}
                                    name={`class`}
                                    value={sendSMS.class}
                                    onChange={(selectedOption) => handlechange('class', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor={`SMStypes`} className="block text-gray-700 text-sm font-bold mb-4">
                                SMS type
                            </label>
                            <div className={`w-full border rounded `}>
                                <Select
                                    options={SMStypes}
                                    // isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id={`SMStypes`}
                                    name={`SMStypes`}
                                    value={sendSMS.SMStypes}
                                    onChange={(selectedOption) => handlechange('SMStypes', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor={`SMSStatus`} className="block text-gray-700 text-sm font-bold mb-4">
                                Delivery Status
                            </label>
                            <div className={`w-full border rounded `}>
                                <Select
                                    options={SMSStatus}
                                    // isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    id={`SMSStatus`}
                                    name={`SMSStatus`}
                                    value={sendSMS.SMSStatus}
                                    onChange={(selectedOption) => handlechange('SMSStatus', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>



                    </div>
                    {/* {errors && <div className="text-red-500">{errors}</div>} */}
                </form >

                {/* END OF FORM */}
                <div className="flex mb-28 flex-col md:flex-row justify-end mt-4 space-y-2 md:space-y-0 md:space-x-2">

                    <button
                        type="button"
                        onClick={handleClear}
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                        <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                    </button>
                    <button
                        type="submit"
                        onClick={handleSearch}
                        disabled={isLoading} // Disable button when loading
                        className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">
                        <FontAwesomeIcon icon={faSearch} /> {isLoading ? 'Searching...' : 'Search'}
                    </button>

                </div>
                <hr className="border-t border-gray-300 my-8" />
                {showTable && <SendSMStable tableData={tableData} />}
            </div>

        </div >
    );
};

export default SendSMSform